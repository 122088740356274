var site = site || {};

site.template = site.template || {};

(function ($) {
  Drupal.behaviors.mppSortV2 = {
    attach: function (context) {
      var self = this;
      var $template = $('.mpp-sort-v2', context);
      var $sortSelect = $('.js-mpp-sort-menu', $template);
      var $sortBtns = $('.js-mpp-sort-option', $template);
      var $sortTitle = $('.js-mpp-sort-title', $template);
      var $sortSets = $('.js-mpp-sort-rendered', $template);
      var $sortContainer = $('.js-product-grid-sorted', context);
      var $selectedLabel = $('.js-mpp-sort__label', $template);

      // If not on an mpp with sorting allowed, do nothing
      if (!$sortContainer.length) {
        return;
      }
      var $sortPromo = $('.product-grid__item--promo', $sortContainer);
      var $hasFranchiseTout = $('.js-has-franchise-tout', context);

      $sortPromo.removeAttr('tabindex');
      $sortBtns.once().on('click', function (e) {
        var $btn = $(this);
        var value = $btn.data('sort-option');
        
        $selectedLabel.text($btn.text());

        if ($btn.attr('disabled')) {
          return;
        }

        $sortContainer.mixItUp('sort', value);
        // hide inline touts. using css classes to override mixitup
        if (value === 'default') {
          $sortPromo.removeClass('hide-touts');
        } else {
          $sortPromo.addClass('hide-touts');
        }
        $sortSets.addClass('hide-sort');
        $sortTitle.removeClass('sort-active');
        $btn.toggleClass('active');
        $btn.attr('selected', 'selected');
        $sortBtns.removeAttr('selected');
      });
      $sortTitle.once().on('click', function (event) {
        var $this = $(this);
        var $thisSort = $this.siblings('.js-mpp-sort-rendered');

        if (!$thisSort.hasClass('hide-sort')) {
          $thisSort.addClass('hide-sort');
          $this.removeClass('sort-active');
        } else {
          $sortSets.addClass('hide-sort');
          $thisSort.removeClass('hide-sort');
          $this.addClass('sort-active');
          event.stopPropagation();

          $('body')
            .once()
            .on('click', function (event) {
              if ($(event.target).closest('.js-mpp-sort-rendered').length === 0) {
                $sortTitle.removeClass('sort-active');
                $sortSets.addClass('hide-sort');
                $('body').off('click');
              }
            });
        }
      });
      $sortSelect.on('change', function () {
        $sortContainer.mixItUp('sort', this.value);
        // hide inline touts. using css classes to override mixitup
        if (this.value === 'default') {
          $sortPromo.removeClass('hide-touts');
        } else {
          $sortPromo.addClass('hide-touts');
        }
      });

      $sortContainer.each(function () {
        var $items = $('.js-grid-item', this);
        var $firstSortVal = $items.eq(0).data('promo-sort'); // getter
        var $this = $(this);

        // special franchise tout case
        // check for franchise tout and change sort promo value of the first tout
        // so this remains in position 1
        if ($hasFranchiseTout.length && $firstSortVal === 1) {
          $items.eq(0).attr('data-promo-sort', 3); // setter
        }

        self.attachRatingsToSort(this);

        // instantiate mixitup

        if (!$this.hasClass('mixitup-initialized')) {
          var $mixItUpGrid = $this.addClass('mixitup-initialized');
          var mixItUpArgs = {
            selectors: {
              target: '.js-product-grid-item--sortable',
              sort: '.js-mpp-sort-option'
            },
            layout: {
              display: 'block'
            }
          };

          $mixItUpGrid
            .on('mixEnd', function () {
              $mixItUpGrid.addClass('mixitup-loaded');
              if ($mixItUpGrid.data('deferredAnimation')) {
                $mixItUpGrid
                  .data('deferredAnimation', false)
                  .data('mixItUp')
                  .setOptions({ animation: { enable: true } });
              }
            })
            .on('mixitup.initialize', function () {
              // required in order to get the grid unstuck, due to OOS items being droped by ticket events
              $mixItUpGrid.mixItUp('destroy');
              $mixItUpGrid.find('.js-product-grid-item').attr('data-bound', '').data('bound', '');
              mixItUpArgs.animation = { enable: false };
              $mixItUpGrid.data('deferredAnimation', true).mixItUp(mixItUpArgs);
            });

          // Init mixItUp
          $mixItUpGrid.mixItUp(mixItUpArgs);
        }
      });
    },
    attachRatingsToSort: function (container) {
      var $productGridItem = $('.js-product-grid-item--sortable', container);

      $productGridItem.each(function () {
        var $item = $(this);
        var $review = $('.product-brief__review-snippet', $item);
        var $prSnippet = $('.pr-snippet-rating-decimal', $item);

        if (!$review.length) {
          return;
        }

        // Observable for reviews snippet update to get the rating value.
        var config = {
          childList: true,
          subtree: true
        };
        var observer = new MutationObserver(function (mutations, observer) {
          mutations.forEach(function (mutation) {
            var $addedNodes = $(mutation.addedNodes);

            if ($addedNodes.length) {
              $addedNodes.find('.pr-snippet-rating-decimal').each(function () {
                var $rating = $(this);

                $item.attr('data-rating-sort', $rating.text());
                observer.disconnect();
              });
            }
          });
        });

        if ($prSnippet.length) {
          $item.attr('data-rating-sort', $prSnippet.text());
        } else {
          $item.attr('data-rating-sort', 0);
        }
        observer.observe($review[0], config);
      });
    }
  };
})(jQuery);
